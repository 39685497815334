import React from 'react'
import Meta from '../components/Meta'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CardService from '../components/CardService'
import CardTestimonial from '../components/CardTestimonial'
import VideosWithDescription from '../components/VideosWithDescription'
import RichText from '../components/RichText'

class IndexRoute extends React.Component {
  render() {
    const routeData = this.props.data
    const metaDataElements = routeData.kontentItemSiteMetadata.elements
    const title = metaDataElements.title.value
    const subtitle = metaDataElements.subtitle.value

    const homepageElements = routeData.kontentItemHomepage.elements

    const aboutMeText = homepageElements.about_me

    const servicesUrl = routeData.kontentItemServicesOverviewPage.elements.slug.value
    const servicesTitle = homepageElements.services_title.value
    const servicesLinkMoreText = homepageElements.services_link_more_text.value
    const serviceItems = []
    const services = homepageElements.services.value
    services.forEach(service => {
      serviceItems.push(<CardService data={service} slug={servicesUrl} showServiceItems="false" key={service.id} />)
    })

    const testimonialsUrl = routeData.kontentItemTestimonialsOverviewPage.elements.slug.value
    const testimonialsLinkMoreText = homepageElements.testimonials_link_more_text.value
    const testimonialsItems = []
    const testimonials = homepageElements.testimonials.value
    testimonials.forEach(testimonial => {
      testimonialsItems.push(<CardTestimonial data={testimonial} slug={testimonialsUrl} key={testimonial.id} />)
    })

    const transformationsOverviewPageUrl = routeData.kontentItemTransformationsOverviewPage.elements.slug.value
    const transformationStoriesTitle = homepageElements.transformation_stories_title.value
    const transformationStoriesLinkMoreText = homepageElements.transformation_stories_link_more_text.value
    const transformationStoriesText = homepageElements.transformation_stories_text
    const transformationStoryUrl = `${transformationsOverviewPageUrl}/${homepageElements.transformation_stories.value[0].elements.slug.value}`

    const videosMainTitle = homepageElements.videos_title.value
    const videosWithDescriptionItems = []
    const videosWithDescription = homepageElements.videos_with_description.value
    videosWithDescription.forEach(videosWithDescription => {
      videosWithDescriptionItems.push(<VideosWithDescription data={videosWithDescription} key={videosWithDescription.id} />)
    })

    return (
      <Layout>
        <div>
          <Meta title={subtitle} postfix={title} description={subtitle} />
          <Header isHomePage />
          <Hero isHomePage title={subtitle} subtitle={title} description={aboutMeText} />
          <section className="favourite-services">
            <div className="container">
              <div className="row row-heading">
                <div className="col-lg-6">
                  <h2>{servicesTitle}</h2>
                </div>
                <div className="col-lg-6 link-more link-more--desktop">
                  <div className="main-purple-button">
                    <Link to={servicesUrl} data-tracking-label="Home_AllServicesDesktop">{servicesLinkMoreText}</Link>
                  </div>
                </div>
              </div>
              <div className="row row-content">
                {serviceItems}
                <div className="col-lg-12 link-more link-more--mobile">
                  <div className="main-purple-button">
                    <Link to={servicesUrl} data-tracking-label="Home_AllServicesMobile">{servicesLinkMoreText}</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="latest-testimonial section section--blue-bg section--inner">
            <div className="container">
              <div className="row row-heading">
                <div className="col-lg-6" />
                <div className="col-lg-6 link-more link-more--desktop">
                  <div className="main-white-button">
                    <Link to={testimonialsUrl} data-tracking-label="Home_AllTestimonialsDesktop">{testimonialsLinkMoreText}</Link>
                  </div>
                </div>
              </div>
              <div className="row row-content">
                <div className="blockquote">
                  {testimonialsItems}
                </div>
                <div className="col-lg-12 link-more link-more--mobile">
                  <div className="main-white-button">
                    <Link to={testimonialsUrl} data-tracking-label="Home_AllTestimonialsMobile">{testimonialsLinkMoreText}</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="transformation-stories">
            <div className="container">
              <div className="row row-heading">
                <div className="col-lg-6">
                  <h2>{transformationStoriesTitle}</h2>
                </div>
              </div>
              <div className="row row-content">
                <div className="col-lg-12">
                  <RichText data={transformationStoriesText} />
                  <div className="main-purple-button">
                    <Link to={transformationStoryUrl} data-tracking-label="Home_TransformationStory">{transformationStoriesLinkMoreText}</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="television">
            <div className="container">
              <div className="row row-heading">
                <div className="col-lg-12">
                  <h2>{videosMainTitle}</h2>
                </div>
              </div>
              {videosWithDescriptionItems}
            </div>
          </section>
          <Footer />
        </div>
      </Layout>
    )
  }
}

export default IndexRoute

export const pageQuery = graphql`
  query IndexQuery {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
      }
    }
    kontentItemHomepage(system: {codename: {eq: "uvodni_stranka"}}) {
      elements {
        about_me {
          value
          links {
            link_id
            url_slug
            type
          }
          images {
            image_id
            description
            url
            fluid(maxWidth: 700) {
              ...KontentAssetFluid
            }
          }
          modular_content {
            ... on kontent_item_component_images_wrapper {
              id
              elements {
                description {
                  value
                }
                photos {
                  value {
                    fluid(maxWidth: 350) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                      aspectRatio
                      src
                      ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
            ... on kontent_item_component_text_and_image {
              id
              elements {
                text {
                  value
                }
                image {
                  value {
                    fluid(maxWidth: 350) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                        aspectRatio
                        src
                        ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
          }           

        }
        services_title {
          value
        }
        services {
          value {
            ... on kontent_item_service {
            id
            elements {
              title {
                value
              }
              slug {
                value                      
              }
              teaser_image {
                value {
                  fluid(maxWidth: 810) {
                    ...KontentAssetFluid
                  }
                  description
                  name
                  url
                }
              }
            }
          }
        }
      }
      services_link_more_text {
        value
      }
      testimonials_title {
        value
      }
      testimonials {
        value {
          ... on kontent_item_testimonial {
            id
            elements {
              slug {
                value                      
              }
              name {
                value
              }
              description {
                value
              }
            }
          }
        }
      }
      testimonials_link_more_text {
        value
      }
      transformation_stories_title {
        value
      }
      transformation_stories {
        value {
          ... on kontent_item_transformation {
            id
            elements {
              slug {
                value                      
              }
            }
          }
        }
      }
      transformation_stories_text {
          value
          links {
            link_id
            url_slug
            type
          }
          images {
            image_id
            description
            url
            fluid(maxWidth: 700) {
              ...KontentAssetFluid
            }
          }
          modular_content {
            ... on kontent_item_component_images_wrapper {
              id
              elements {
                description {
                  value
                }
                photos {
                  value {
                    fluid(maxWidth: 350) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                      aspectRatio
                      src
                      ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
            ... on kontent_item_component_text_and_image {
              id
              elements {
                text {
                  value
                }
                image {
                  value {
                    fluid(maxWidth: 350) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                      aspectRatio
                      src
                      ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
          }           
      }    
      transformation_stories_link_more_text {
        value
      } 
      videos_title {
        value
      }
      videos_with_description {
        value {
          ... on kontent_item_videos_with_description {
            id
            elements {
              title {
                value
              }
              description {
                value
              }
              videos {
                value {
                  ... on kontent_item_video_external {
                    id
                    elements {
                      title {
                        value
                      }
                      url {
                        value
                      }
                      teaser_image {
                        value {
                          fluid(maxWidth: 300) {
                            ...KontentAssetFluid
                          }
                          description
                          name
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }  
  kontentItemServicesOverviewPage(system: {codename: {eq: "cenik_a_sluzby"}}) {
    elements {
      slug {
        value
      }
    }
  }
  kontentItemTestimonialsOverviewPage(system: {codename: {eq: "reference___testimonialy"}}) {
    elements {
      slug {
        value
      }
    }
  }
  kontentItemTransformationsOverviewPage(system: {codename: {eq: "promeny"}}) {
    elements {
      slug {
        value
      }
    }
  }
 }
`
