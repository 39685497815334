import React from 'react'
import Waves from '../../assets/images/waves.inline.svg'
import HeroImage from '../../assets/images/vizazistka-portret.jpg'
import RichText from '../RichText'


class Hero extends React.Component {
  render() {
    const heroTitle = this.props.title
    const heroSubtitle = this.props.subtitle
    const heroDescription = this.props.description

    return (
      <div>
        <div className="hero">
          <div className="container">
            <div className="text-and-image row">
              <div className="text-and-image__text col-lg-12">
                <div className="hero__content">
                  <div className="hero__content--left">
                    <div className="hero__title-name-portrait">
                      <div className="hero__title-name">
                        <div className="hero__name">{heroTitle}</div>
                        <h1 className="hero__title">{heroSubtitle}</h1>
                      </div>
                      <div className="hero__portrait">
                        <img src={HeroImage} alt="Vižážistka Drahomíra Ondrúšová" width="250" height="388" />
                      </div>
                    </div>
                    <div className="hero__description">
                      <RichText data={heroDescription} />
                    </div>
                    <div className="hero__contact-mail main-purple-button">
                      <a data-tracking-label="Hero_Tel" href="tel:+420606438155">Tel.: 606438155</a>
                    </div>
                    <div className="hero__contact-tel">
                      <a data-tracking-label="Hero_Mail" href="mailto:daja.ondrusova@seznam.cz">Mail: daja.ondrusova@seznam.cz</a>
                    </div>
                  </div>
                  <div className="hero__content--right">
                    <div className="hero__decoration">
                      <img src={HeroImage} alt="Vižážistka Drahomíra Ondrúšová" width="250" height="388" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Waves />
          </div>
        </div>
      </div>
    )
  }
}

export default Hero
